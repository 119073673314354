<template>
  <div class="folder-item">
    <div class="folder-item-icon">
      <el-image :style="{ width: '86.39px', height: '68.76px' }" :src="folderIcom" />
    </div>
    <div class="folder-item-name">
      <span :title="name">{{ name }}</span>
    </div>
  </div>
</template>

<script>
import { ref, watch } from 'vue'

export default {
  name: 'FolderItem',
  props: {
    name: {
      type: String,
      required: true,
      default: 'new folder'
    },
    isTrue: {
      type: Boolean,
      default: false
    }
  },
  setup(props) {
    const dir = require('@assets/image/map/dir.png')
    const diractive = require('@assets/image/map/dirActive.png')
    const folderIcom = ref(props.isTrue ? diractive : dir)
    watch(
      () => props.isTrue,
      newvalue => {
        if (newvalue) {
          folderIcom.value = diractive
        } else {
          folderIcom.value = dir
        }
      }
    )
    return { folderIcom }
  }
}
</script>

<style lang="scss">
.folder-item {
  width: 160px;
  height: 105px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  cursor: pointer;
  &-icon {
    margin: 5px 0;
  }
  &-name {
    width: 210px;
    color: #707070;
    font-weight: bold;
    font-size: 16px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 0 20px;
    box-sizing: border-box;
    text-align: center;
  }
}
</style>
